#main {
  padding-top: 0 !important;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  #main {
    width: 1024px !important;
  }
}

#AddPlantingWizard {
  border: 1px solid grey;
}
#AddPlantingWizard .columns {
  display: flex;
  flex-direction: row;
}
#AddPlantingWizard .columns .progress {
  height: 100%;
  width: 180px;
  background-color: white;
  box-shadow: none;
}
#AddPlantingWizard .columns .info {
  width: 100%;
  border-left: 1px solid #dedede;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9kYzcyYzQ0OC9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nIiwic291cmNlcyI6WyJBZGRQbGFudGluZ1dpemFyZC5zY3NzIiwiLi4vLi4vLi4vYXNzZXRzL3N0eWxlc2hlZXRzL21peGlucy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdBO0VBQ0U7RUFDQTtFQUNBOztBQ0xBO0VERUY7SUFLSTs7OztBQUlKO0VBRUU7O0FBRUE7RUFDRTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAnLi4vLi4vLi4vYXNzZXRzL3N0eWxlc2hlZXRzL2NvbG9ycyc7XG5AaW1wb3J0ICcuLi8uLi8uLi9hc3NldHMvc3R5bGVzaGVldHMvbWl4aW5zJztcblxuI21haW4ge1xuICBwYWRkaW5nLXRvcDogMCAhaW1wb3J0YW50O1xuICBwYWRkaW5nLWxlZnQ6IDA7XG4gIHBhZGRpbmctcmlnaHQ6IDA7XG4gIEBpbmNsdWRlIGRlc2t0b3Bfb25seSB7XG4gICAgd2lkdGg6IDEwMjRweCAhaW1wb3J0YW50O1xuICB9XG59XG5cbiNBZGRQbGFudGluZ1dpemFyZCB7XG5cbiAgYm9yZGVyOiAxcHggc29saWQgZ3JleTtcblxuICAuY29sdW1ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuXG4gICAgLnByb2dyZXNzIHtcbiAgICAgIGhlaWdodDogMTAwJTtcbiAgICAgIHdpZHRoOiAxODBweDtcbiAgICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xuICAgICAgYm94LXNoYWRvdzogbm9uZTtcbiAgICB9XG5cbiAgICAuaW5mbyB7XG4gICAgICB3aWR0aDogMTAwJTtcbiAgICAgIGJvcmRlci1sZWZ0OiAxcHggc29saWQgJHdpemFyZC1ib3JkZXItZ3JheTtcbiAgICB9XG4gIH1cblxufSIsIkBtaXhpbiBkZXNrdG9wX29ubHkge1xuICBAbWVkaWEgKG1pbi13aWR0aDogNzY4cHgpIHtcbiAgICBAY29udGVudDtcbiAgfVxufVxuXG5AbWl4aW4gbW9iaWxlX29ubHkge1xuICBAbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgICBAY29udGVudDtcbiAgfVxufVxuIl19 */