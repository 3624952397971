.MobileExpandableLink {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.MobileExpandableLink .expandable {
  border: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 20px;
}
.MobileExpandableLink .children {
  display: flex;
  flex-direction: column;
}
.MobileExpandableLink button {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.MobileExpandableLink img {
  height: 16px;
  margin-right: 5px;
}
.MobileExpandableLink img.down {
  transform: rotate(90deg);
}
.MobileExpandableLink p {
  margin: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9kYzcyYzQ0OC9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL2FwcGxpY2F0aW9uIiwic291cmNlcyI6WyJNb2JpbGVFeHBhbmRhYmxlTGluay5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy4uLy4uLy4uL2Fzc2V0cy9zdHlsZXNoZWV0cy9jb2xvcnMnO1xuXG4uTW9iaWxlRXhwYW5kYWJsZUxpbmsge1xuICBib3JkZXItdG9wOiAxcHggc29saWQgJHdpemFyZC1ib3JkZXItZ3JheTtcbiAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICR3aXphcmQtYm9yZGVyLWdyYXk7XG5cbiAgLmV4cGFuZGFibGUge1xuICAgIGJvcmRlcjogbm9uZTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIHBhZGRpbmc6IDAgMjBweDtcbiAgfVxuXG4gIC5jaGlsZHJlbiB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICB9XG5cbiAgYnV0dG9uIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDtcbiAgICBib3JkZXI6IG5vbmU7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgcGFkZGluZzogMDtcbiAgfVxuXG4gIGltZyB7XG4gICAgaGVpZ2h0OiAxNnB4O1xuICAgIG1hcmdpbi1yaWdodDogNXB4O1xuICB9XG5cbiAgaW1nLmRvd24ge1xuICAgIHRyYW5zZm9ybTogcm90YXRlKDkwZGVnKTtcbiAgfVxuXG4gIHAge1xuICAgIG1hcmdpbjogMDtcbiAgfVxuXG4gIC5jYXJldCB7XG4gIC8vICAgY29udGVudDogJyc7XG4gIC8vICAgd2lkdGg6IDEwcHg7XG4gIC8vICAgaGVpZ2h0OiAxMHB4O1xuICAvLyAgIHRyYW5zZm9ybTogcm90YXRlKC00NWRlZyk7XG4gIC8vICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAvLyAgIGJvcmRlcjogbm9uZTtcbiAgLy8gICBib3JkZXItcmlnaHQ6IDNweCBzb2xpZCByZWQ7XG4gIC8vICAgYm9yZGVyLXRvcDogM3B4IHNvbGlkIGJsdWU7XG4gIC8vICAgYmFja2dyb3VuZC1jb2xvcjogdHJhbnNwYXJlbnQ7XG4gIC8vICAgcGFkZGluZzogNXB4O1xuICAvLyAgIG1hcmdpbi10b3A6IC01cHg7XG4gIH1cblxuICAuZG93bi5jYXJldCB7XG4gIC8vICAgdHJhbnNmb3JtOiByb3RhdGUoMTM1ZGVnKTtcbiAgfVxuXG59Il19 */